import React, { useEffect } from 'react';
import { globalHistory } from '@reach/router'
import Generic from 'components/Layouts/Generic';
import gtmEvents from '../utils/functions/gtmFunctions.js';

const title = 'Peuples autochtones';

const body = `
  <p>BAnQ travaille en concertation avec les différentes instances représentatives des peuples autochtones, notamment les commissions scolaires, le Conseil en éducation des Premières Nations et les conseils de bande, afin de réfléchir à la question de la représentation des peuples autochtones et de leur histoire au sein de ses différentes plateformes éducatives.</p>
  <p>La nouvelle image de la plateforme – auparavant intitulée La Ligne du temps du Québec – s’inscrit directement dans cette démarche collaborative, qui vise à présenter l’histoire du Québec à travers des voix plurielles. À terme, l’intégration d’une ligne du temps pour chacune des 11 nations autochtones du Québec permettra de poser un nouveau regard sur l’histoire au profit des enseignants et des élèves.</p>
  <p>Si vous voulez prendre part à la réflexion, n'hésitez pas à <a href="https://education.banq.qc.ca/nous-joindre/" target="_blank">communiquer avec nous.</a></p>
`;

const CommunautesAutochtones = (props) => {
  useEffect(() => {
      gtmEvents.sendVirtualPageView(title, globalHistory.location.href.href, globalHistory.location.pathname);
  }, []);
  return <Generic location={props?.location} title={title} body={body} />;
};

export default CommunautesAutochtones;
